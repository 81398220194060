<!-- 书画学院打印准考证与点名表 -->
<template>
    <div class="StudentDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">书画学院打印准考证与点名表</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" inline class="form"  label-width="150px">
            <el-form-item label="登记单号">
                <el-input  placeholder="" disabled :value="form.number"></el-input>
            </el-form-item>
            <el-form-item label="提供机构">
                <el-input  placeholder="" disabled :value="form.provide"></el-input>
            </el-form-item>
            <el-form-item label="准考证号">
                <el-input  placeholder="" disabled :value="form.admission"></el-input>
            </el-form-item>
            <el-form-item label="考生姓名">
                <el-input  placeholder="" disabled :value="form.name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input  placeholder="" disabled :value="form.telephone"></el-input>
            </el-form-item>
            <el-form-item label="登记经办">
                <el-input  placeholder="" disabled :value="form.handle"></el-input>
            </el-form-item>
            <el-form-item label="下单日期">
                <el-input  placeholder="" disabled :value="form.lowerDate"></el-input>
            </el-form-item>
            <el-form-item label="考试科目">
                <el-input  placeholder="" disabled :value="form.subject"></el-input>
            </el-form-item>
            <el-form-item label="等级">
                <el-input  placeholder="" disabled :value="form.grade"></el-input>
            </el-form-item>
            <el-form-item label="考试日期">
                <el-input  placeholder="" disabled :value="form.testDate"></el-input>
            </el-form-item>
            <el-form-item label="考试开始时间">
                <el-input  placeholder="" disabled :value="form.startDate"></el-input>
            </el-form-item>
            <el-form-item label="截止时间">
                <el-input  placeholder="" disabled :value="form.endDate"></el-input>
            </el-form-item>
            <el-form-item label="考试时长">
                <el-input  placeholder="" disabled :value="form.duration"></el-input>
            </el-form-item>
            <el-form-item label="考试场地">
                <el-input  placeholder="" disabled :value="form.testField"></el-input>
            </el-form-item>
            <el-form-item label="场地地址">
                <el-input  placeholder="" class="textArea-width"  disabled :value="form.address"></el-input>
            </el-form-item>
            <el-form-item label="单据说明">
                <el-input type="textarea" disabled class="textArea-width" placeholder="" :value="form.explain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   number:"",//登记单号
                   provide:"",//提供机构
                   admission:"",//准考证号
                   name:"",//考生姓名
                   telephone:"",//联系电话
                   handle:"",//登记经办
                   lowerDate:"",//下单日期
                   subject:"",//考试科目
                   grade:"",//等级
                   testDate:"",//考试日期
                   startDate:"",//考试开始时间
                   endDate:"",//截止时间
                   duration:"",//考试时长
                   testField:"",//考试场地
                   address:"",//场地地址
                   explain:""//单据说明
                }
            }
        },
        created() {
            // 初次渲染
            console.log(this.$route.query.id)
            this.$request({
                url:'/api/Admission/detail',
                method:"POST",
                data:{
                    id:this.$route.query.id
                }
            }).then(res=>{
                console.log(res)
                if(res.code==1){
                    let data = res.data
                    let form = this.form
                    form.number=data.enroll_number//登记单号
                    form.provide=data.org_name//提供机构
                    form.admission=data.certificate_no//准考证号
                    form.name=data.name//考生姓名
                    form.telephone=data.phone//联系电话
                    form.handle=data.order_name//登记经办
                    form.lowerDate=data.exam_date//下单日期
                    form.subject=data.course//考试科目
                    form.grade=data.exam_level//等级
                    form.testDate=data.exam2_date//考试日期
                    form.startDate=data.exam_startdate//考试开始时间
                    form.endDate=data.exam_enddate//截止时间
                    form.duration=data.exam_duration//考试时长
                    form.testField=data.site_name//考试场地
                    form.address=data.address//场地地址
                    form.explain=data.remark//单据说明
                }
            })
        },
        methods:{
           goBack() { //返回
               this.$router.go(-1);
           }, 
        }
    }
</script>

<style scoped="scoped">
    .StudentDetails{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    .el-input{
        width: 300px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form .el-select{
        width: 300px;
        height: 37px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #fff;
        color: #000000;
    }
    ::v-deep .el-textarea.is-disabled .el-textarea__inner{
        background-color: #fff;
        color: #000000;
    }
    .form ::v-deep .input-width{
        width: 1220px !important;
    }
</style>
